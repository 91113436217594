import { ESCAPE } from '@angular/cdk/keycodes';
import { DOCUMENT } from '@angular/common';
import {
    Directive,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    Output,
    SimpleChanges
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Directive({
    selector: '[escapePressed]',
    standalone: true
})

/**
 * Example usage:
 * <div (escapePressed)="close()"></div>
 */
export class EscapePressedDirective {
    @Input() escapeIsOpen: boolean = false;
    @Output() public escapePressed = new EventEmitter<MouseEvent>();

    constructor(private _elementRef: ElementRef, @Inject(DOCUMENT) private document: Document) {}

    private subscribeToEscapeEvent() {
        fromEvent(this.document, 'keydown')
            .pipe(
                take(1),
                filter((event: any) => event.keyCode === ESCAPE)
            )
            .subscribe((event: any) => this.escapePressed.emit(event));
    }

    ngOnChanges(simpleChanges: SimpleChanges) {
        if (simpleChanges.escapeIsOpen.currentValue) {
            this.subscribeToEscapeEvent();
        }
    }
}

