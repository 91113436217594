import { DOCUMENT } from '@angular/common';
import {
    ChangeDetectorRef,
    Directive,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    NgZone,
    Output,
    SimpleChanges
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { filter, skip, take } from 'rxjs/operators';

@Directive({
    selector: '[clickOutside]',
    standalone: true
})

/**
 * Example usage:
 * <div (clickOutside)="close()"></div>
 */
export class ClickOutsideDirective {
    @Input() isOpen: boolean = false;
    @Input() skipFirstClick: boolean = true;
    @Input() ignoredClasses: string[] = [];
    @Output() public clickOutside = new EventEmitter<MouseEvent>();

    constructor(
        private _elementRef: ElementRef,
        @Inject(DOCUMENT) private document: Document,
        private ngZone: NgZone,
        private cdr: ChangeDetectorRef
    ) {}

    private subscribeToClickEvent() {
        // this.ngZone.runOutsideAngular(() => {
            fromEvent(this.document, 'click')
                .pipe(
                    skip(this.skipFirstClick ? 1 : 0),
                    filter(event => {
                        return (
                            !this._elementRef.nativeElement.contains(event.target) ||
                            !this.ignoredClasses.some(ignoredClass =>
                                this._elementRef.nativeElement.querySelectorAll('.' + ignoredClass)
                            )
                        );
                    }),
                    take(1)
                )
                .subscribe((event: any) => {
                    if (!event.target) {
                        return;
                    }

                    this.clickOutside.emit(event);
                    this.cdr.markForCheck();
                });
        // });
    }

    ngOnChanges(simpleChanges: SimpleChanges) {
        if (simpleChanges.isOpen.currentValue) {
            this.subscribeToClickEvent();
        }
    }
}

